import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'









function Latestd() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(58);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [latestdesign, setLatestdesign] = useState([])
    const navigate = useNavigate()

    const latestdesignCollectionRef = collection(db, "LatestDesign");
    useEffect(() => {

        const getLatestdesign = async () => {
            const data = await getDocs(latestdesignCollectionRef);
            setLatestdesign(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getLatestdesign()
    }, [])
    return (

        <>

            <div className="album py-1">

                <div className="container">

                    <div className="row">
                        {latestdesign.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((a2zindia) => {
                            return (
                                <div className="col-md-4 mt-3" >
                                    <div className='hoverimage1'>
                                        <img className="card-img-top image2" src={a2zindia.img} alt={a2zindia.title} />
                                        <div className="overlay1"> {a2zindia.title}</div>
                                    </div>
                                    <div className="card-body">
                                        <center>


                                        </center>





                                        <div className="d-flex justify-content-between align-items-center">



                                        </div>
                                    </div>


                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Latestd;



