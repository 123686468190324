


import React from "react";
import Header from "../inc/Header";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

function Eventpage() {
  return (
    <>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br/>
      <br/>
      <ScrollToTop smooth top="100" color="Darkblue" />
      <div className="album py-4">

        <div className="container " >

          <div className="row  ">
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border box  shadow">
           
              
                <div className="card-body">
        <center>
                    <h6 style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b> MARRIAGE EVENTS</b></h6>
                    <Link to="/">

                    </Link>


                  <Link to="/Marriage">
                    <button className="btn btn-outline-dark"> Know More</button>
                  </Link>
                  </center>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border box  shadow">
                
                <div className="card-body">
            <center>
                    <h6 style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>COMPANY EVENT</b></h6>
                    <Link to="/Companyevent">

                    </Link>

          
                  <Link to="/Companyevent">
                    <button className="btn btn-outline-dark"> Know More</button>
                  </Link>
                  </center>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border box  shadow">


                <div className="card-body">
                  <center>
                    <h6 style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>BIRTHDAY EVENT</b></h6>
                    <Link to="/">

                    </Link>





                    <Link to="/Birthday">
                      <button className="btn btn-outline-dark"> Know More</button>
                    </Link>
                  </center>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

    </>
  );
}

export default Eventpage