


import React, { useState, useEffect }  from 'react';

import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import Header from '../inc/Header';
import logoa2zservice from "../images/live.jpg";
// import Service from "../images/service.jpg";

function Livemember() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(1);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [livemember, setLivemember] = useState([])
    const navigate = useNavigate()

    const livememberCollectionRef = collection(db, "livemember");
    useEffect(() => {

        const getLivemember = async () => {
            const data = await getDocs(livememberCollectionRef);
            setLivemember(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getLivemember()
    }, [])
    return (
        
<>
<Header/>
<br/>
<br/>
<br/>
<br/>
                <br/>
                <center>
                    <h3 style={{color:"Gray"}} > <b>LIVE MEMBER</b></h3>
                </center>

<div className="album py-4">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search " name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {livemember.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.CODNO.toLowerCase().includes(searchTerm.toLowerCase())

       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((Interiorpage) => { return (
       
            <div className="col-md-4" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="" src={logoa2zservice}  alt="A2Z_SERVICE" />
                        <img  className="card-img-top" src={Interiorpage.PIC}   alt={ Interiorpage.NAME} />
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>Name:- { Interiorpage.NAME} </b></h6>
                                <h6 className="card-text mb-2">Membership Number:- {Interiorpage.CODNO}</h6>
                                <p className="card-text mb-2">Date of Birth:- {Interiorpage.DOB}</p>
                                <div className="d-flex justify-content-between align-items-center">
                                <p className="card-text mb-2"><small>Blood Group:- {Interiorpage.BLOOD}</small></p>
                                <img  className=""  src={Interiorpage.QR} height="85" width="115" alt={ Interiorpage.QR} />
                                        </div>

                                           <p className="card-text mb-2">Email ID- {Interiorpage.EMAIL}</p>

<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        LAST MONTH SALARY
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      <p className="card-text mb-2">    {Interiorpage.jan}</p>
      <p className="card-text mb-2">    {Interiorpage.feb}</p>
      <p className="card-text mb-2">    {Interiorpage.march}</p>
      <p className="card-text mb-2">    {Interiorpage.april}</p>
      <p className="card-text mb-2">    {Interiorpage.may}</p>
      <p className="card-text mb-2">    {Interiorpage.june}</p>
      </div>
    </div>
  </div>
  </div>
                              
                              
                                    

                                       
                                    
                              
                           
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                 
                            </div>
                            </div>
</>

);
}

export default Livemember;
