
import React from 'react'
import {Link} from "react-router-dom";
import Header from "../inc/Header";
import Domectics from "../images/Chimneys.jpg";
import Commercial from "../images/Chimneys.jpg";
import { 
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
     FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,
  
     LinkedinShareButton,
     LinkedinIcon,
     TwitterIcon,
  }  from "react-share";
function Chimneys() {
  return (
    <div>
      <Header/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="album py-5">
        
        <div className="container " >

            <div className="row  ">
       
            <div className="col-md-6 " >
                        <div className="card mb-4 border-White box shadow  ">
                        
                            <Link to="#">
  
                        <img src={Domectics} className="card-img-top image5 "   alt="Domectics" />
                      
                        </Link>
        
                            <div className="card-body">
                                
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>DOMESTICS</b></h6>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      < FacebookShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                        
                         
                        
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-6" >
                        <div className="card mb-4 border box  shadow">
                
                          <Link to="#">
                        <img src={Commercial} className="card-img-top image5"   alt="Commercial" /></Link>
        
    
                            <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>COMMERCIAL</b></h6>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      < FacebookShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
    </div>
  )
}

export default Chimneys
