


import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import Header from '../inc/Header';
// import Service from "../images/service.jpg";
import ScrollToTop from "react-scroll-to-top";
function Soler(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [dast, setDast] = useState([])
    const navigate = useNavigate()

    const dastCollectionRef = collection(db, "dast");
    useEffect(() => {

        const getDast = async () => {
            const data = await getDocs(dastCollectionRef);
            setDast(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getDast()
    }, [])
    return (

        <>
            <Header />
            <br />

            <br />
            <br />
            <br />
            <br />
            <br/>
            <ScrollToTop smooth top="100" color="Darkblue" />

            <br />
            <center>
                <h3 style={{ color: "Gray" }} > <b>DAST TREATMENT</b></h3>
            </center>



            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {dast.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.img.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((Interiorpage) => {
                            return (

                                <div className="col-md-6 " >
                                    <div className="card mb-4 border-White box shadow">
                                        <img className="card-img-top" src={Interiorpage.img} alt="Dast" />
                                        <div className="card-body">




                                            <a href="tel: +91 8116521754" className="btn btn- btn-secondary">  Book Now  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                            </svg>  +91 8116521754</a>
                                            <div className="d-flex justify-content-between align-items-center">






                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </>

    );
}

export default Soler;
