import React from "react";
import { Link } from "react-router-dom";
import Header from "../inc/Header";

import card from "../images/KITCHENA2Z.jpg";
import card2 from "../images/TA2Z.jpg";
import card3 from "../images/BUSINESSA2Z.jpg";
import Room from "../images/room2.jpeg"
import Kithcen2 from "../images/kitchen2.jpeg"

import Waterfilter from "../images/waterpurifier.jpg";
import interior from "../images/interior-home.jpg";
import Construction from "../images/constructions.jpg";
import Marregeevent from "../images/birthday-event.jpg";

import "./Home.css";
import ScrollToTop from "react-scroll-to-top";
import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import Slider from "../inc/Slider";
import Latestd from "./Latestd";

function Home(props) {
  return (
    <>

      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="album py-1">

        <div className="container ">
          <h5>A2z India, </h5>
        </div>
      </div>
      <div className="album py-3">

        <div className="container ">
          <Slider />
        </div>
      </div>


      <ScrollToTop smooth top="100" color="Darkblue" />
      <div className="back">

        <div className="album py-0">

          <div className="container ">
            <div className="d-flex justify-content-between align-items-center">

              <button className="descrip"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
              </svg>  Quality </button>

              <button className="descrip">  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
              </svg> Trust </button>

              <button className="descrip"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
              </svg> Time Maintain </button>
            </div>
          </div>
        </div>
      </div>


      <div className="album py-4">
        <div className="container " >
          <div className="row ">
            <div className="col-md-6 mt-6">


              <div className="card" >
                <div className="shrink">
                  <img src={Room} class="card-img-top" alt="..." />
                </div>
                <div class="card-body">
                  <h6 class="card-title">Daining</h6>
                  <p class="card-text"> Quality & Trust  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 mt-6">

              <div className="card" >
                <div className="shrink">
                  <img src={Kithcen2} class="card-img-top" alt="..." />
                </div>
                <div class="card-body">
                  <h6 class="card-title">kitchen</h6>
                  <p class="card-text">Quality & Trust </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="album py-1">
        <div className="container " >
          <h4>Latest Design (Our Work)</h4>
        </div>
      </div>
      <Latestd />
      <br />
      <br />
      {/* 
      <div className="album py-4">

        <div className="container " >

          <div className="row ">

            <div className="col-md-3 mt-6">
              <div className="card mb-4 border-White box shadow  ">
                <div className="a2zservice">
                  <Link to="/Water_treatment">

                    <img src={Waterfilter} className="card-img-top image5 " alt="Waterfilter" />

                  </Link>
                  <div className="middle5">
                    <div className="text" > <h5>WATER TREATMENT</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">

                  <div className="d-flex justify-content-between align-items-center">
                    <p style={{ color: "DarkSlateBlue" }} className="card-text mb-2">WATER</p>
                    <Link to="/">

                    </Link>

                  
                    <button type="button" class="sharebutton" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </button>

                 
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Share Now </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            < FacebookShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <FacebookIcon size={30} round={true} />
                            </FacebookShareButton>

                            <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <WhatsappIcon size={30} round={true} />
                            </ WhatsappShareButton>

                            <  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <TwitterIcon size={30} round={true} />
                            </ TwitterShareButton>

                            <  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >

                              <  LinkedinIcon size={30} round={true} />
                            </ LinkedinShareButton>

                            <  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <  EmailIcon size={30} round={true} />
                            </ EmailShareButton>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">

                  </div>
                  <Link to="/Water_treatment">
                    <button className="button button1"> Know More</button>
                  </Link>
                </div>
              </div>

            </div>



        
            <div className="col-md-3 mt-6" >
              <div className="card mb-4 border box  shadow">
                <div className="a2zservice">
                  <Link to="/Interiorpage">
                    <img src={interior} className="card-img-top image5" alt="interior" /></Link>
                  <div className="middle5">
                    <div className="text" > <h5>INTERIOR</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>INTERIOR</b></h6>
                    <Link to="/">

                    </Link>

                   
                    <button type="button" class="sharebutton" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </button>

                 
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Share Now </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            < FacebookShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <FacebookIcon size={30} round={true} />
                            </FacebookShareButton>

                            <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <WhatsappIcon size={30} round={true} />
                            </ WhatsappShareButton>

                            <  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <TwitterIcon size={30} round={true} />
                            </ TwitterShareButton>

                            <  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >

                              <  LinkedinIcon size={30} round={true} />
                            </ LinkedinShareButton>

                            <  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <  EmailIcon size={30} round={true} />
                            </ EmailShareButton>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link to="/Interiorpage">
                    <button className="button button1"> Know More</button>
                  </Link>
                </div>
              </div>

            </div>
            <div className="col-md-3 mt-6" >
              <div className="card mb-4 border box  shadow">
                <div className="a2zservice">
                  <Link to="/construction">
                    <img src={Construction} className="card-img-top image5" alt="Construction" /></Link>
                  <div className="middle5">
                    <div className="text" > <h5>CONSTRUCTION</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">

                    <h6 style={{ color: "DarkSlateBlue		" }} className="card-text mb-0"><b>CONSTRACTION</b></h6>


                   
                    <button type="button" class="sharebutton" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </button>

                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Share Now </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            < FacebookShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <FacebookIcon size={30} round={true} />
                            </FacebookShareButton>

                            <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <WhatsappIcon size={30} round={true} />
                            </ WhatsappShareButton>

                            <  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <TwitterIcon size={30} round={true} />
                            </ TwitterShareButton>

                            <  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >

                              <  LinkedinIcon size={30} round={true} />
                            </ LinkedinShareButton>

                            <  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <  EmailIcon size={30} round={true} />
                            </ EmailShareButton>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/constraction">
                    <button className="button button1"> Know More</button>
                  </Link>
                </div>
              </div>

            </div>
            <div className="col-md-3 mt-6">
              <div className="card mb-4 border box  shadow">
                <div className="a2zservice">
                  <Link to="/events">
                    <img src={Marregeevent} className="card-img-top image5" alt="Marregeevent" /></Link>
                  <div className="middle5">
                    <div className="text" > <h5>EVENT </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b> EVENTS</b></h6>
                    <Link to="/events">

                    </Link>

                    <button type="button" class="sharebutton" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </button>

                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Share Now </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            < FacebookShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <FacebookIcon size={30} round={true} />
                            </FacebookShareButton>

                            <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <WhatsappIcon size={30} round={true} />
                            </ WhatsappShareButton>

                            <  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <TwitterIcon size={30} round={true} />
                            </ TwitterShareButton>

                            <  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >

                              <  LinkedinIcon size={30} round={true} />
                            </ LinkedinShareButton>

                            <  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                            >
                              <  EmailIcon size={30} round={true} />
                            </ EmailShareButton>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/events">
                    <button className="button button1"> Know More</button>
                  </Link>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div> */}

      <div className="backcolor">
        <br/>
        <br />
        <center>
          <div className="mainh">
            <h3 >Best Selling Product</h3>
          </div>
        </center>
        <div className="container">
          <div className="row">
            <div className="col-md-4">

            </div>
          </div>
        </div>
        <div className="album py-4">
          <div className="container ">
            <div className="row  ">
              <div className="col-md-4 mt-2">
                <div className=" mb-4">
                  <center>
                    <Link to="/">
                      <div className="radus">
                        <img src={card} className="card-img-top mb-3" alt="Sofa1" />
                      </div>

                    </Link>
                    <div className="card-body">

                      <h6 style={{ color: "DarkSlateBlue	" }} className="card-text">KITCHEN ROOM</h6>
                      <Link to="/">
                        <p className="button10 button11 me-4 mb-2">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                      </svg></p>
                      </Link>

                      <button type="button" className="btn btn btn-light me-2">
                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button> 
                      <br/>
                      < FacebookShareButton url="https" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <FacebookIcon size={30} round={true} />
                      </FacebookShareButton>

                      <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <WhatsappIcon size={30} round={true} />
                      </ WhatsappShareButton>

                      <  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <TwitterIcon size={30} round={true} />
                      </ TwitterShareButton>

                      <  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >

                        <  LinkedinIcon size={30} round={true} />
                      </ LinkedinShareButton>

                      <  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <  EmailIcon size={30} round={true} />
                      </ EmailShareButton>
                      <div className="d-flex justify-content-between align-items-center">

                      </div>

                    </div>
                  </center>
                </div>

              </div>



              {/* style={{textAlign: "center"}} */}
              <div className="col-md-4 mt-2" >
                <div className=" mb-4 border  ">
                  <center>
                    <Link to="/">
                      <div className="radus">
                        <img src={card2} className="card-img-top mb-3" alt="Table" />
                      </div>
                    </Link>
                    <div className="card-body">
                      <h6 style={{ color: "DarkSlateBlue" }} className="card-text">INTERIOR</h6>
                      <Link to="/">
                        <p className="button10 button11 me-4 mb-2">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                      </svg></p>
                      </Link>
                      <button type="button" className="btn btn btn-light me-1">
                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button> 
                      <br/>
                      < FacebookShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <FacebookIcon size={30} round={true} />
                      </FacebookShareButton>

                      <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <WhatsappIcon size={30} round={true} />
                      </ WhatsappShareButton>

                      <  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <TwitterIcon size={30} round={true} />
                      </ TwitterShareButton>

                      <  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >

                        <  LinkedinIcon size={30} round={true} />
                      </ LinkedinShareButton>

                      <  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                      >
                        <  EmailIcon size={30} round={true} />
                      </ EmailShareButton>
                    </div>
                    </center>
                </div>

              </div>
              <div className="col-md-4 mt-2" >
                <div className=" mb-4 border">
                  <center>
                  <Link to="/">
                  <div className="radus">
                    <img src={card3} className="card-img-top mb-3" alt="Table" />
                    </div>
                    </Link>
                  <div className="card-body">
                    <h6 style={{ color: "DarkSlateBlue" }} className="card-text">PUJA ROOM</h6>
                    <Link to="/">
                      <p className="button10 button11 me-4 mb-2">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                      </svg></p>
                    </Link>
                    <button type="button" className="btn btn btn-light me-1">
                      Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </button>
                    <br/>
                     < FacebookShareButton url="https://www.polyage.in/centre_table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                    >
                      <FacebookIcon size={30} round={true} />
                    </FacebookShareButton>

                    <  WhatsappShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                    >
                      <WhatsappIcon size={30} round={true} />
                    </ WhatsappShareButton>

                    <  TwitterShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                    >
                      <TwitterIcon size={30} round={true} />
                    </ TwitterShareButton>

                    <  LinkedinShareButton url="/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                    >

                      <  LinkedinIcon size={30} round={true} />
                    </ LinkedinShareButton>

                    <  EmailShareButton url="/" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                    >
                      <  EmailIcon size={30} round={true} />
                    </ EmailShareButton>
                  </div>
                  </center>
                </div>

              </div>

            </div>

          </div>

        </div>
        <br/>
      </div>
     


    </>
  );
}

export default Home;