import React from 'react'
import Header from '../inc/Header'
import AddBook from '../AddBook'

function Booka() {
  return (
    <div>
        <Header/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <AddBook/>
    </div>
  )
}

export default Booka