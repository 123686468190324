import React from "react";

import { Link } from "react-router-dom";
import "./Header.css";
import logoa2z from "../images/logoa2zs.jpg";
import ReactWhatsapp from "react-whatsapp";

function Header() {
  return (


    <>

      <div className="fixed-top">
        <div className="bcchange">
          <marquee className="mt-1">  नमस्कार INDIA! Welcome A2Z India, *India's Best Service Provider </marquee>

        </div>
       
        <div className="a2zindia">
          <div className="album py-2">

            <div className="container">

              <div className="row ">

                <div className="col-md-4" >
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <h4 style={{ color: "Darkblue" }} > <b> {ctime} </b></h4> */}

                    
                      <a href="tel: +91 8116521754" style={{ pading: "1px" }} className="buttonbook"> <small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg></small></a>
                 


<ReactWhatsapp number="+91 9831187919" style={{ pading: "2px" }}  className="btn btn-success btn-sm" message="I want to Membership, I want to Contact"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg></ReactWhatsapp>


                    
                      <a href="mailto: dibyendu0072023@gmail.com " style={{ pading: "2px" }} className="buttonbook"> <small> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg></small></a>
                    
<Link to="/book_appointment">
                      <button style={{ pading: "2px" }} className="buttonbook"> <small>Book Appointment</small></button>
                    </Link>
                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar bg-white shadow">
          <div className="container-fluid">
            <Link to="/" >
              <div className="radiuslogo">
              <img src={logoa2z} height="50" width="120" alt="logoa2z" />
              </div>
            </Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0 ">

                <li className="nav-item">
                  <div className="newa">
                    <Link to="/" className="nav-link active " style={{ color: "Black" }} aria-current="page"> <b>HOME</b></Link>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link active" aria-current="page" style={{ color: "Black" }} href="#"><b>ABOUT US</b></Link>
                </li>
                {/* <li className="nav-item">
          <Link to="/Services" className="nav-link active" style={{color:"Black"}} aria-current="page" > <b>SERVICES</b></Link>
        </li> */}



                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" style={{ color: "Black" }} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <b> OUR SERVICES</b>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/Interiorpage" className="dropdown-item" > <b> Interior</b></Link></li>
                    <li><Link to="/Exterior" className="dropdown-item" > <b> Exterior</b></Link></li>
                    <li><Link to="/Water_treatment" className="dropdown-item" > <b> Water Treatment</b></Link></li>
                    <li><Link to="/Airtreatment" className="dropdown-item" > <b> Air Treatment</b></Link></li>
                    <li><Link to="/Dast" className="dropdown-item" > <b>Dast Treatment</b></Link></li>


                    <li><Link to="/Chimneys" className="dropdown-item" > <b> Chimney's</b></Link></li>
                    <li><Link to="/Marketing" className="dropdown-item" > <b> Online & Offline Marketing</b></Link></li>
                    <li><Link to="/construction" className="dropdown-item" ><b> Civil Work</b></Link></li>
                    <li><Link to="/soler" className="dropdown-item" ><b> Soler System</b></Link></li>
                    <li><Link to="/events" className="dropdown-item" ><b> Event</b></Link></li>




                  </ul>

                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" style={{ color: "Black" }} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <b> MEMBER</b>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

                    <li><Link to="/Livemember" className="dropdown-item" > <b> LIVE MEMBER</b></Link></li>






                  </ul>

                </li>

                <li className="nav-item">
                  <Link to="/Contact" className="nav-link active" style={{ color: "Black" }} aria-current="page" > <b>CONTACT</b></Link>
                </li>

              </ul>

              <form className="d-flex">

                <a href="https://sso.godaddy.com/?region=eu1&saas=2&app=ox&path=v1%2Fsaml%2Fox&realm=pass&status=24" target="_blank" rel="noopener noreferrer" className="headerlog" type="submit">Login <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                  <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg></a>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;