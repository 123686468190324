import React from "react";
import {Link }from "react-router-dom";
import Header from "../inc/Header";
import Bed from "../images/beds.jpg";
import Daining from "../images/drawingroom.jpg";
import Drawing from "../images/drawingroom.jpg";
import Belcony from "../images/belcony.jpg";
import Bathroom from "../images/bathroom.jpg";
import MKitchen from "../images/kitchen.jpg";
import Pujaroom from "../images/puja_room.jpg";
import Sofa from "../images/sofa-single.jpg";

function Interiorpage (props){
    return(
        <>
        <Header/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <br/>
        <div className="album py-4">
        
        <div className="container " >

            <div className="row  ">
       
            <div className="col-md-4 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/Bed">
                       
                        <img src={Bed} className="card-img-top "   alt="Bed" />
              
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Bed</b></h6>
                                <Link to="/Bed">
                                <p   className="card-text mb-3">Konw More</p>
                                </Link>
                              
            
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                        
                         
                        
                               <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border box  shadow">
                          <Link to="/diningdrwaing">
                        <img src={Drawing} className="card-img-top"   alt="Drawing" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b> Dining & Drwaing</b></h6>
                                <Link to="/diningdrwaing">
                                <p   className="card-text mb-3">Know More</p>
                                </Link>
                   
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border box  shadow">
                          <Link to="/Bathroom">
                        <img src={Bathroom} className="card-img-top"   alt="Bathroom" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Bathroom</b></h6>
                                <Link to="/Bathroom">
                                <p   className="card-text mb-3">Know More</p>
                                </Link>
                        
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border box  shadow">
                          <Link to="/Balcony">
                        <img src={Belcony} className="card-img-top"   alt="Belcony" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Belcony</b></h6>
                                <Link to="/Balcony">
                                <p   className="card-text mb-3">Know More</p>
                                </Link>
                        
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border box  shadow">
                          <Link to="/Modularkitchen">
                        <img src={MKitchen} className="card-img-top"   alt="MKitchen" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Maduler Kitchen</b></h6>
                                <Link to="/Modularkitchen">
                                <p   className="card-text mb-3">Know More</p>
                                </Link>
                        
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border box  shadow">
                          <Link to="/Pujaroom">
                        <img src={Pujaroom} className="card-img-top"   alt="Pujaroom" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Pujaroom</b></h6>
                                <Link to="/Pujaroom">
                                <p   className="card-text mb-3">Know More</p>
                                </Link>
                        
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border box  shadow">
                          <Link to="/Sofa">
                        <img src={Sofa} className="card-img-top"   alt="Sofa" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Sofa</b></h6>
                                <Link to="/Sofa">
                                <p   className="card-text mb-3">Know More</p>
                                </Link>
                        
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
        </>
    );
}

export default Interiorpage;