
import './App.css';
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Interior from './components/pages/Interior';
import Contact from './components/pages/Contact';
import Constraction from './components/pages/Constraction';
import Services from './components/pages/Services';
import Birthday from './components/pages/Birthday';
import Marriage from './components/pages/Marriage';
import Footer from './components/inc/Footer';
import About from './components/pages/About';
import Exterior from './components/pages/Exterior';
import Waterpage from './components/pages/Waterpage';
import Air from './components/pages/Air';
import Chimneys from './components/pages/Chimneys';
import Memberp from './components/pages/Memberp';
import Interiorpage from './components/pages/Interiorpage';
import Soller from './components/pages/Soller';
import Marketing from './components/pages/Marketing';
import Dast from './components/pages/Dast';
import Company from './components/pages/Company';
import Dainingdrawing from './components/pages/Dainingdrawing';
import Bathroom from './components/pages/Bathroom';
import Balcony from './components/pages/Balcony';
import Modular from './components/pages/Modular';
import Pujaroom from './components/pages/Pujaroom';
import Sofa from './components/pages/Sofa';
import Eventpage from './components/pages/Eventpage';
import Waterd from './components/pages/Waterd';
import Livemember from './components/pages/Livemember';
import Booka from './components/pages/Booka';



function App() {
  return (
    <>
     <Router>
  
    <div>
   
    
     <Routes>
     
    
     <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Bed" element={<Interior/>}/>
      <Route axact path="/Contact" element={<Contact/>}/>
      <Route axact path="/construction" element={<Constraction/>}/>
      <Route axact path="/Services" element={<Services/>}/>
      <Route axact path="/Birthday" element={<Birthday/>}/>
      <Route axact path="/Marriage" element={<Marriage/>}/>
      <Route axact path="/about" element={<About/>}/>
      <Route axact path="/Exterior" element={<Exterior/>}/>
      <Route axact path="/Water_treatment" element={<Waterpage/>}/>
      <Route axact path="/Airtreatment" element={<Air/>}/>
      <Route axact path="/Chimneys" element={<Chimneys/>}/>
      <Route axact path="/member" element={<Memberp/>}/>
      <Route axact path="/Interiorpage" element={<Interiorpage/>}/>
      <Route axact path="/soler" element={<Soller/>}/>
      <Route axact path="/Marketing" element={<Marketing/>}/>
      <Route axact path="/Dast" element={<Dast/>}/>
      <Route axact path="/Companyevent" element={<Company/>}/>
       <Route axact path="/diningdrwaing" element={<Dainingdrawing/>}/>
       <Route axact path="/Bathroom" element={<Bathroom/>}/>
       <Route axact path="/Balcony" element={<Balcony/>}/>
       <Route axact path="/Modularkitchen" element={<Modular/>}/>
       <Route axact path="/Pujaroom" element={<Pujaroom/>}/>
       <Route axact path="/Sofa" element={<Sofa/>}/>
       <Route axact path="/book_appointment" element={<Booka/>}/>
       <Route axact path="/events" element={<Eventpage/>}/>
            <Route axact path="/Waterdomestic" element={<Waterd/>}/>
            <Route axact path="/Livemember" element={<Livemember/>}/>
     </Routes>
    
    <Footer/>
   </div>
   
   </Router>
   </>

  );
}

export default App;
