
import React from "react";
import Header from "../inc/Header";
import AddMember from "../AddMember";
function Memberp (props){
    return(
                <>

                <Header/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <center>
                    <h4 style={{color:"Gray"}}>JOIN MEMBERSHIP FORM</h4>
                </center>
                <AddMember/>
                </>
    );
}

export default Memberp;