



import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyB7R5h-BfMHlbcDYpfz5tpV2rCRf7Z9XaE",
    authDomain: "a2z-service-c10b9.firebaseapp.com",
    projectId: "a2z-service-c10b9",
    storageBucket: "a2z-service-c10b9.appspot.com",
    messagingSenderId: "467593840114",
    appId: "1:467593840114:web:64253150cb208aa3e2176b",
    measurementId: "G-E23VW51QEQ"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;



