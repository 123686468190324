import React from 'react'
import Header from "../inc/Header";

import "./About.css";

function About() {
  return (
    <div>
      <Header/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    <br/>
      <center>
        <div className="colo">
        <h3 ><b>WE COVERD YOU AT EVERY STEP IN YOUR LIFE.........</b> </h3>
        </div>
      </center>
      <br/>
    </div>
  )
}

export default About
